@import "../../styles/kiwanis-colors";

.kclSelectedClubType {
  border-radius: 0 0 30px 0!important;
  box-shadow: 0 0 6px #535353;
  position: relative;
  cursor: pointer;
}

.kclCheckIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 3px solid #B3AEB8;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .kclSelectedClubType {
    width: 31.5%!important;
  }
}

