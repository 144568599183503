// ---------- Kiwanis brand book colors ----------- //
$kiwanis-blue-color: #003874;
$kiwanis-light-blue-color: #99CAEA;
$kiwanis-gold-color: #B49759;
$kiwanis-black-color: #000000;
$kiwanis-light-gray-color: #E6E7E9;
$kiwanis-gray-color: #A9A8A9;
$kiwanis-white-color: #FFFFFF;

// ---------- Kiwanis clubs colors ----------- //
$kiwanis-club-color: $kiwanis-blue-color;
$kiwanis-cki-club-color: #00A5D9;
$kiwanis-key-club-color: $kiwanis-gold-color;
$kiwanis-builders-club-color: #7E71B1;
$kiwanis-action-club-color: #F18D16;
$kiwanis-k-kids-club-color: #7FB637;

$kcl-gray-color: #E6E6E6;
