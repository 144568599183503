@import "../../styles/kiwanis-colors";

.selectedClub {
  background-color: $kcl-gray-color;
  padding-left: 1.5rem!important;
  padding-bottom: 1.5rem!important;
}

.kclCardPadding {
  padding-left: 1.5rem!important;
  padding-bottom: 1.5rem!important;
}

.kclClubInfo {
  display: flex;
  font-weight: 700;
}

.kclCardLabel {
  font-weight: 600;
}
