@import "src/styles/kiwanis-colors";

.kiwanis-club-locator .kclNavbar {
  min-height: 90px;
  background-color: $kiwanis-blue-color !important;
  border-bottom: 4px solid $kiwanis-gold-color;
  align-items: end;
}

.kiwanis-club-locator .kclNavbarBrand {
  font-size: 1.3em;
  font-weight: 600;
  color: $kiwanis-light-gray-color;
}

@media only screen and (max-width: 991px) {
  .kiwanis-club-locator .kclNavbarBrand {
    margin-left: 5rem;
    font-size: 1.3em;
  }
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(180,151,89, 100)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
