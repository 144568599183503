.kclContainer {
  padding-top: 5vw;
  padding-left: 7vw!important;
  padding-right: 7vw!important;
}

@media screen and (min-width: 768px) {
  .kclContainer {
    padding-left: 5vw!important;
    padding-right: 5vw!important;
  }
}

@media screen and (min-width: 992px) {
  .kclContainer {
    padding-left: 10vw!important;
    padding-right: 10vw!important;
  }
}

@media screen and (min-width: 1200px) {
  .kclContainer {
    padding-left: 15vw!important;
    padding-right: 15vw!important;
  }
}

@media screen and (min-width: 1400px) {
  .kclContainer {
    padding-left: 20vw!important;
    padding-right: 20vw!important;
  }
}
