@import "../../styles/kiwanis-colors";

$mapImage1024: "../../img/home-page/map_1024px.png";
$mapImage1920: "../../img/home-page/map_1920px.png";

.kclLensCover img {
  width: 35vw;
  position: absolute;
  max-width: none;
}

.kclDescription p {
  width: 100%;
}

.containerLinkStyle {
  max-width: max-content;
  z-index: 11;
}

.linkStyle {
  color: $kiwanis-white-color;
  background-color: $kiwanis-gold-color;
  text-transform: uppercase;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 3px 3px 2px 1px rgba(192, 191, 192);
  text-align: center;
}

.linkStyle:active {
  color: $kiwanis-blue-color;
}

.linkStyle:hover {
  color: $kiwanis-blue-color;
}

.textIndent {
  text-indent: 1.5em;
}

.kclMapCover {
  width: 100%;
  background-image: url($mapImage1024);
  background-repeat: no-repeat;
  background-size: contain;
  height: 39vw;
}

@media screen and (max-width: 375px) {
  .kclLensCover img {
    width: 45vw;
  }
}

@media screen and (min-width: 375px) {
  .kclLensCover img {
    width: 45vw;
  }
}

@media screen and (min-width: 576px) {
  .kclLensCover img {
    width: 53vw;
  }
}

@media screen and (min-width: 992px) {
  .kclLensCover img {
    width: 45vw;
  }
  .kclMapCover {
    background-image: url($mapImage1920);
  }
}

@media screen and (min-width: 1200px) {
  .kclDescription {
    padding-right: 132px;
  }
}

@media screen and (min-width: 1400px) {
  .kclLensCover img {
    width: 36vw;
  }
}
