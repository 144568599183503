.kiwanis-club-locator {
  @import "styles/kiwanis-colors";

  $line-height-base: 1.5;

  $kcl-box-shadow: 4px 4px 0 -1px rgba(180, 151, 89, 100);
  $kcl-margin-left: 1.5rem;

  $primary: $kiwanis-blue-color;
  $primary-rgb: $kiwanis-blue-color;

  $secondary: $kcl-gray-color;
  $secondary-rgb: $kcl-gray-color;

  $navbar-light-brand-hover-color: $kiwanis-white-color;
  $nav-link-color: $kiwanis-white-color;
  $nav-link-hover-color: $kiwanis-gray-color;

  $font-size: inherit;
  $border-radius-sm: 0;

  $btn-font-size: inherit;
  $btn-font-size-sm: inherit;
  $btn-border-radius-sm: 0;
  $btn-focus-box-shadow: $kcl-box-shadow;
  $btn-active-box-shadow: $kcl-box-shadow;

  $form-select-font-size: inherit;
  $form-select-focus-box-shadow: $kcl-box-shadow;
  $form-select-padding-x-sm: 1.5rem;
  $form-select-bg-position: right 1rem center;

  $form-check-input-border-radius: 0;
  $form-check-input-checked-color: $kiwanis-gold-color;
  $form-check-input-checked-bg-color: $kiwanis-gold-color;
  $form-check-input-focus-box-shadow: $kiwanis-gold-color;
  $form-check-radio-border-radius: 0;

  $input-font-size: inherit;
  $input-font-size-sm: inherit;
  $input-focus-border-color: $kiwanis-gold-color;
  $input-focus-color: $kiwanis-black-color;
  $input-focus-box-shadow: $kcl-box-shadow;
  $input-padding-x-sm: 1.5rem;

  $card-border-width: 2px;
  $card-border-color: $kiwanis-light-gray-color;
  $card-border-radius: 0;
  $card-spacer-x: 10px;
  $card-spacer-y: 10px;

  @import "~bootstrap/scss/bootstrap";

  .btn {
    min-width: 6rem;
    font-size: inherit;

    &:disabled {
      @include button-variant(
              $background: rgb(216, 216, 216),
              $border: rgb(216, 216, 216),
              $disabled-background: rgb(216, 216, 216),
              $disabled-border: rgb(216, 216, 216)
      );
    }

    &:hover {
      box-shadow: $kcl-box-shadow;
    }

    &:active {
      box-shadow: $kcl-box-shadow;
    }

    &:focus {
      box-shadow: $kcl-box-shadow;
    }
  }

  .form-control-sm {
    height: unset;
    line-height: 1.5;
  }

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }


  /************************************************
  *	                Headings                      *
  ************************************************/

  h1, h2, h3, h4, h5, h6 {
    color: #333333;
    color: rgba(0, 0, 0, 0.8);
    font-family: Arial-Bold, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.4;
    word-wrap: break-word;
    margin: 4px 0;
    padding: 4px 0;
  }

  h1 {
    font-size: 2.6em;
    letter-spacing: -1px;
  }

  h2 {
    font-size: 2.4em;
    letter-spacing: -1px;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  h6 {
    font-size: 1.1em;
  }

  hr {
    padding: 0;
    margin: 0.5rem 0;
  }

  ul.customUl {
    padding-left: 1.5rem;
    line-height: 1.5;
  }

  .kclMtMb {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h1.headline {
    font-family: sans-serif;
    font-size: 3em;
  }

  .headline {
    font-size: 3em;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: -1px;
    word-wrap: break-word;
    margin: 0;
    padding: 0 0 12px 0;
  }

  .kcl-fw-600 {
    font-weight: 600;
  }

  .kcl-heading-fw-600 {
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 1.5rem;
    margin-top: 1.1rem;
  }

  .kcl-ml {
    margin-left: $kcl-margin-left;
    line-height: 1.5;
  }

  .form-label {
    margin-left: 1.5rem;
    margin-bottom: 0.2rem;
  }

  .cursor_pointer {
    cursor: pointer;
  }

  :focus {
    outline: none !important;
  }

  .kclRowAfter::after {
    content: none
  }

  /************************************************
  *	           Custom Scrollbar	           		  *
  ************************************************/
  /* width */

  @media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }


  /************************************************
  *	           Mobile Devices		     		  *
  ************************************************/

  @media only screen and (max-width: 419px) {
    div h1.headline {
      font-size: 2em;
    }
  }

}
