.mapContainerStyle {
  width: 100%;
  height: 100vh;
}

.kclLeftPanel {
  border-right: 1px solid #E6E6E6;
}

.kclLeftPanelScroll {
  overflow-y: scroll;
  height: calc(100vh - 202px);
  padding-right: 0;
}

.containerStyleWhenNoLocation {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1px!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerStyle {
  padding-left: 1px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.kclPr12Sm {
  padding-right: 12px;
}

.kclPr12 {
  padding-right: 12px;
}

.kclMr12 {
  margin-right: 12px;
}

.kclMr {
  margin-right: 19px;
}

@media only screen and (min-width: 992px) {
  .kclLeftPanel {
    flex: 0 0 450px !important;
    padding-right: 0!important;
  }
  .kclPr12Sm {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .kclMr {
    margin-right: 12px;
  }
  .kclLeftPanel {
    flex: 1 0 100vw !important;
  }
  .kclLeftPanelScroll {
    overflow-y: visible;
    height: 100%;
  }
  .containerStyle {
    height: 35vh;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .containerStyle {
    height: 50vh;
  }
}
